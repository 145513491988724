<template>
    <div class="hidden xs:flex">
        <div class="w-1/3">
            <img
                v-if="federalState.attributes.Flagge"
                class="w-full mt-2 rounded-sm"
                :src="media">
                <!--src="~assets/bw.png">-->
        </div>
        <div class=" w-2/3 ml-4">
            <h1>{{ federalState.attributes.Name }}</h1>
            <NuxtLink :to="localePath('/bundeslaender/'+url)">
                weitere Infos
            </NuxtLink>
        </div>
    </div>
    <div class="xs:hidden flex flex-col justify-center m-0.5 relative">
        <img
            v-if="federalState.attributes.Flagge"
            class="rounded"
            :src="media">
        <div class="text-center text-sm underline mt-0.5 -mb-0.5">
            <NuxtLink :to="localePath('/bundeslaender/'+url)">
                {{ federalState.attributes.Kuerzel }}
                <span class="absolute inset-0" />
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    federalState: {
        type: Object,
        default: () => ({})
    }
})

const {public: publicConfig} = useRuntimeConfig()

const url = computed(() => {
    const Seite = props.federalState?.attributes?.Seite?.data?.attributes
    let url = Seite?.url
    if (Seite?.Weiterleiten && Seite?.Unterseiten?.data?.length > 0) {
        url += '/' + Seite.Unterseiten.data[0].attributes.url
    }
    return url
})

const media = ref(null)
await useAsyncData(`${props.federalState?.attributes?.Flagge?.data[0]?.attributes?.url}`, () => useStrapiMedia(props.federalState?.attributes?.Flagge?.data[0]?.attributes?.url ?? ''), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => {
    const mediaUrl = response.data?.value ?? null
    media.value = mediaUrl.replace(publicConfig.strapiUrl, publicConfig.publicStrapiUrl)
})
</script>
